<template>
  <div>
    <div style="margin-bottom: 20px">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item >强算子解读</el-breadcrumb-item>
        <el-breadcrumb-item>{{ type == "add" ? "新增" : "编辑" }}强算子解读</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <el-card shadow="always" class="add-container">
      <div>
        <div class="title">强算子内容</div>
        <el-form ref="form" :model="policyForm" :rules="rules" label-position="right" label-width="100px" class="policy-content-form">
          <el-form-item label="发布日期" prop="date">
            <el-date-picker v-model="policyForm.date" type="date" placeholder="选择日期" style="width: 270px" value-format="YYYY-MM-DD"></el-date-picker>
          </el-form-item>
          <el-form-item label="强算子标题" prop="title">
            <el-input v-model="policyForm.title" style="width: 270px" maxlength="100"></el-input>
          </el-form-item>
          <el-form-item label="强算子内容">
            <el-input type="textarea" v-model="policyForm.content" style="width: 270px" maxlength="21845"></el-input>
          </el-form-item>
          <el-form-item label="原文链接" prop="link">
            <el-input v-model="policyForm.link" style="width: 270px" maxlength="200"></el-input>
          </el-form-item>
        </el-form>
      </div>

      <div>
        <div class="title">强算子解读</div>
        <el-form ref="forms" :model="analysisForm" label-position="right" label-width="130px" class="policy-analysis-form">
          <el-form-item label="强算子解读">
            <el-input type="textarea" v-model="analysisForm.analysis" style="width: 270px" maxlength="21845"></el-input>
          </el-form-item>
          <el-form-item label="影响大盘（可选）">
            <div class="choose-container">
              <div v-for="(item, index) in analysisForm.daPanForm" :key="index">
                <div style="display: flex">
                  <div>大盘</div>
                  <div style="color: blue; cursor: pointer; margin-left: 50px" @click="delEffect(item.key)">
                    删除
                  </div>
                </div>
                <!-- <div style="margin-top: 5px" class="input">
                  <div>
                    <el-input :disabled="item.isCheck" v-model.trim="item.stock" placeholder="请输入股票代码或股票名称" style="width: 270px">
                    </el-input>
                  </div>

                </div> -->
                <div style="margin-top: 5px">
                  <el-select v-model="item.degree" placeholder="请选择影响程度" style="width: 270px">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div style="color: blue; cursor: pointer" v-show="analysisForm.daPanForm.length < 1" @click="addEffect">
              + 添加影响
            </div>
          </el-form-item>
          <el-form-item label="影响行业（可选）">
            <div class="choose-container">
              <div v-for="(item, index) in analysisForm.industryForm" :key="index">
                <div style="display: flex">
                  <div>行业{{ index + 1 }}</div>
                  <div style="color: blue; cursor: pointer; margin-left: 50px" @click="delIndustry(item.key)">
                    删除
                  </div>
                </div>
                <div style="margin-top: 5px">
                  <el-select v-model="item.industry" filterable placeholder="请选择行业" style="width: 270px" @change="changeIndustryDegree(item.industry, index)">
                    <el-option v-for="item in industryOptions" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                  <!-- <el-autocomplete v-model="item.industry" :fetch-suggestions="querySearchAsync" placeholder="请选择行业" style="width:270px;"></el-autocomplete> -->
                </div>
                <div style="margin-top: 5px">
                  <el-select v-model="item.degree" placeholder="请选择影响程度" style="width: 270px">
                    <el-option v-for="item in degreeOptions" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div style="color: blue; cursor: pointer" @click="addIndustry">
              + 添加行业
            </div>
          </el-form-item>
          <el-form-item label="影响主题（可选）">
            <div class="choose-container">
              <div v-for="(item, index) in analysisForm.subjectForm" :key="index">
                <div style="display: flex">
                  <div>主题{{ index + 1 }}</div>
                  <div style="color: blue; cursor: pointer; margin-left: 50px" @click="delSubject(item.key)">
                    删除
                  </div>
                </div>
                <div style="margin-top: 5px">
                  <el-select v-model="item.subject" filterable placeholder="请选择主题" style="width: 270px" @change="changeSubjectDegree(item.subject, index)">
                    <el-option v-for="item in subjectOptions" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                  <!-- <el-autocomplete v-model="item.subject" :fetch-suggestions="querySearchSubjectAsync" placeholder="请选择主题" style="width:270px;"></el-autocomplete> -->
                </div>
                <div style="margin-top: 5px">
                  <el-select v-model="item.degree" placeholder="请选择影响程度" style="width: 270px">
                    <el-option v-for="item in degreeOptions" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div style="color: blue; cursor: pointer" @click="addSubject">
              + 添加主题
            </div>
          </el-form-item>
          <el-form-item label="影响个股（可选）">
            <div class="choose-container">
              <div v-for="(item, index) in analysisForm.stockForm" :key="index">
                <div style="display: flex">
                  <div>个股{{ index + 1 }}</div>
                  <div style="color: blue; cursor: pointer; margin-left: 50px" @click="delStock(item.key)">
                    删除
                  </div>
                </div>
                <div style="margin-top: 5px" class="input">
                  <div>
                    <el-input :disabled="item.isCheck" v-model.trim="item.stock" placeholder="请输入股票代码或股票名称" style="width: 270px">
                    </el-input>
                  </div>

                  <div>
                    <el-button v-show="!item.isCheck" @click="validateStockCode(item.stock, index)">校验</el-button>
                    <el-button v-show="item.isCheck">已校验</el-button>
                  </div>
                </div>
                <div style="margin-top: 5px">
                  <el-select v-model="item.degree" placeholder="请选择影响程度" style="width: 270px">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div style="color: blue; cursor: pointer" @click="addStock">
              + 添加个股
            </div>
          </el-form-item>
        </el-form>
      </div>

      <div class="btn-group">
        <div class="btn submit" @click="submit">提交</div>
        <div class="btn cancel" @click="cancel">取消</div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { reactive, ref, toRefs, onMounted, getCurrentInstance } from "vue";
import { useRouter, useRoute } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import { formatterDate } from "@/utils/format";
export default {
  setup() {
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const route = useRoute();
    const form = ref();
    const state = reactive({
      policyForm: {
        date: "",
        title: "",
        content: "",
        link: "",
      },
      analysisForm: {
        analysis: "",
        industryForm: [],
        subjectForm: [],
        stockForm: [],
        daPanForm: []   //影响大盘
      },
      type: "",

      industryOptions: [],
      subjectOptions: [],
      options: [],
      degreeOptions: [],

      id: "",

      rules: {
        date: [
          {
            type: "date",
            required: true,
            message: "请选择发布日期",
            trigger: "change",
          },
        ],
        title: [
          { required: true, message: "请输入强算子标题", trigger: "blur" },
        ],
        link: [{ required: true, message: "请输入原文链接", trigger: "blur" }],
      },

      timeout: null,
      stockState: false,
      newIndustryArray: [],
      newSubjectArray: [],
      newStockArray: [],
    });

    if (route.path.indexOf("edit") > -1) {
      state.type = "edit";
    } else if (route.path.indexOf("add") > -1) {
      state.type = "add";
    }

    onMounted(() => {
      //查询行业枚举值
      getIndustry();
      //查询主题枚举值
      getSubject();
      //影响程度枚举值
      getDegree();
      //如果是编辑状态，回填信息
      if (state.type == "edit") {
        let editDetailData = JSON.parse(localStorage.getItem("detailData"));

        const {
          releaseDate: date,
          title,
          content,
          originalLink,
          policyInterpretation,
          influenceList,
          id,
        } = editDetailData;
        state.policyForm.date = formatterDate(date);
        state.policyForm.title = title;
        state.policyForm.content = content;
        state.policyForm.link = originalLink;
        state.analysisForm.analysis = policyInterpretation;
        state.id = id;
        influenceList.forEach((item) => {
          if (item.influenceType == 1) {
            //1代表行业
            state.analysisForm.industryForm.push({
              industry: item.name,
              degree: item.influenceLevel,
              key: Date.now(),
            });
          }
          if (item.influenceType == 2) {
            //2代表主题
            state.analysisForm.subjectForm.push({
              subject: item.name,
              degree: item.influenceLevel,
              key: Date.now(),
            });
          }
          if (item.influenceType == 3) {
            //3代表个股
            state.analysisForm.stockForm.push({
              stock: item.name,
              isCheck: false,
              degree: item.influenceLevel,
              key: Date.now(),
            });
          }
          if(item.influenceType == 4) {
            //4代表大盘
            state.analysisForm.daPanForm.push({
              name: item.name,
              degree: item.influenceLevel,
              key: Date.now(),
            });
          }
        });
      }
    });

    const validateStockCode = (item, index) => {
      if (!item) {
        ElMessage({
          message: "请输入股票代码或股票名称",
          type: "error",
        });
        return;
      }
      proxy
        .$get("/cts/back/policy/validate" + "?codeOrName=" + item)
        .then((res) => {
          if (res.resultState === "200") {
            ElMessage({
              message: "校验成功",
              type: "success",
            });
            changeStockInfo(res.data.name, index);
            // state.analysisForm.stockForm[index].stock = res.data.name;
            // state.analysisForm.stockForm[index].isCheck = true;
          } else {
            state.analysisForm.stockForm[index].stock = "";
          }
        })
        .catch((err) => { });
    };

    const getIndustry = () => {
      let params = {
        searchType: 1,
      };
      proxy
        .$get("/cts/back/policy/influenceEnum", { params })
        .then((result) => {
          if (result.resultState == 200) {
            const { data } = result;
            state.industryOptions = data;
          }
        })
        .catch((err) => { });
    };
    const getSubject = () => {
      let params = {
        searchType: 2,
      };
      proxy
        .$get("/cts/back/policy/influenceEnum", { params })
        .then((result) => {
          if (result.resultState == 200) {
            const { data } = result;
            state.subjectOptions = data;
          }
        })
        .catch((err) => { });
    };

    const getDegree = () => {
      proxy
        .$get("/cts/back/policy/influenceLevelEnum")
        .then((result) => {
          if (result.resultState == 200) {
            const { data } = result;
            state.degreeOptions = data;
            state.options = data;
          }
        })
        .catch((err) => { });
    };

    //添加行业
    const addIndustry = () => {
      state.analysisForm.industryForm.push({
        industry: "",
        degree: "",
        key: Date.now(),
      });
      state.newIndustryArray = [...state.analysisForm.industryForm];
    };
    const changeIndustryDegree = (value, index) => {
      if (state.analysisForm.industryForm.length > 1) {
        state.newIndustryArray.splice(index, 1);
        let status = state.newIndustryArray.some((item) => {
          return item.industry === value;
        });
        if (status) {
          ElMessage({
            message: "请勿重复添加",
            type: "warning",
          });
          // state.analysisForm.industryForm.splice(index, 1);
          state.analysisForm.industryForm[index] = {
            industry: "",
            degree: "",
            key: Date.now(),
          };
        }
      }
    };
    //删除行业
    const delIndustry = (key) => {
      let arrIndex = -1;
      state.analysisForm.industryForm.forEach((item, index) => {
        if (item.key === key) {
          arrIndex = index;
        }
      });
      if (arrIndex !== -1) {
        state.analysisForm.industryForm.splice(arrIndex, 1);
      }
    };
    //添加主题
    const addSubject = () => {
      state.analysisForm.subjectForm.push({
        subject: "",
        degree: "",
        key: Date.now(),
      });
      state.newSubjectArray = [...state.analysisForm.subjectForm];
    };
    const changeSubjectDegree = (value, index) => {
      if (state.analysisForm.subjectForm.length > 1) {
        state.newSubjectArray.splice(index, 1);
        let status = state.newSubjectArray.some((item) => {
          return item.subject === value;
        });
        if (status) {
          ElMessage({
            message: "请勿重复添加",
            type: "warning",
          });
          // state.analysisForm.subjectForm.splice(index, 1);
          state.analysisForm.subjectForm[index] = {
            subject: "",
            degree: "",
            key: Date.now(),
          };
        }
      }
    };

    //删除主题
    const delSubject = (key) => {
      let arrIndex = -1;
      state.analysisForm.subjectForm.forEach((item, index) => {
        if (item.key === key) {
          arrIndex = index;
        }
      });
      if (arrIndex !== -1) {
        state.analysisForm.subjectForm.splice(arrIndex, 1);
      }
    };

    //添加个股
    const addStock = () => {
      state.analysisForm.stockForm.push({
        stock: "",
        degree: "",
        isCheck: false,
        key: Date.now(),
      });
      state.newStockArray = [...state.analysisForm.stockForm];
    };

    const changeStockInfo = (value, index) => {
      state.newStockArray.splice(index, 1);
      let status = state.newStockArray.some((item) => {
        return item.stock === value;
      });
      if (status) {
        ElMessage({
          message: "请勿重复添加",
          type: "warning",
        });
        state.analysisForm.stockForm[index].stock = "";
      } else {
        state.analysisForm.stockForm[index].stock = value;
        state.analysisForm.stockForm[index].isCheck = true;
      }
    };
    //删除个股
    const delStock = (key) => {
      let arrIndex = -1;
      state.analysisForm.stockForm.forEach((item, index) => {
        if (item.key === key) {
          arrIndex = index;
        }
      });
      if (arrIndex !== -1) {
        state.analysisForm.stockForm.splice(arrIndex, 1);
      }
    };

    //添加大盘
    const addEffect = () => {
      state.analysisForm.daPanForm.push({
        subject: "",
        degree: "",
        key: Date.now(),
      });
      state.newEffectArray = [...state.analysisForm.daPanForm];
    }
    //删除大盘
    const delEffect = (key) => {
      let arrIndex = -1;
      state.analysisForm.daPanForm.forEach((item, index) => {
        if (item.key === key) {
          arrIndex = index;
        }
      });
      if (arrIndex !== -1) {
        state.analysisForm.daPanForm.splice(arrIndex, 1);
      }
    };

    const submit = () => {
      let pattern = /^(http|https):\/\/([\w.]+\/?)\S*/;
      if (!pattern.test(state.policyForm.link)) {
        ElMessage({
          message: "请输入以http://或者https://开头的链接地址",
          type: "error",
        });
        return;
      }

      let params = {
        content: state.policyForm.content,
        influenceList: [],
        originalLink: state.policyForm.link,
        policyInterpretation: state.analysisForm.analysis,
        releaseDate: state.policyForm.date,
        title: state.policyForm.title,
      };
      if (state.analysisForm.industryForm.length > 0) {
        state.analysisForm.industryForm.forEach((item) => {
          if (item.degree && item.industry) {
            params.influenceList.push({
              influenceLevel: item.degree,
              influenceType: "1",
              name: item.industry,
            });
          }
        });
      }
      if (state.analysisForm.subjectForm.length > 0) {
        state.analysisForm.subjectForm.forEach((item) => {
          if (item.degree && item.subject) {
            params.influenceList.push({
              influenceLevel: item.degree,
              influenceType: "2",
              name: item.subject,
            });
          }
        });
      }

      if (state.analysisForm.stockForm.length > 0) {
        state.analysisForm.stockForm.forEach((item) => {
          if (!item.degree && !item.stock) {
            item.isCheck = true;
          }
        });
        let check = state.analysisForm.stockForm.every((item) => {
          return item.isCheck;
        });
        if (check) {
          // let a = setArr(state.analysisForm.stockForm);
          // console.log(a);
          state.analysisForm.stockForm.forEach((item) => {
            if (item.degree && item.stock) {
              params.influenceList.push({
                influenceLevel: item.degree,
                influenceType: "3",
                name: item.stock,
              });
            }
          });
        } else {
          ElMessage({
            message: "请先校验股票代码或股票名称",
            type: "error",
          });
          return;
        }
      }

      if (state.analysisForm.daPanForm.length > 0) {
        state.analysisForm.daPanForm.forEach((item) => {
          if (item.degree) {
            params.influenceList.push({
              influenceLevel: item.degree,
              influenceType: "4",
              name: '大盘',
            });
          }
        });
      }

      let url = ""; //接口地址
      //如果当前是编辑状态
      if (state.type == "edit") {
        let id = {
          id: state.id,
        };
        params = { ...params, ...id };
        url = "/cts/back/policy/modify"; //修改接口
      } else if (state.type == "add") {
        url = "/cts/back/policy/add"; //新增接口
      }

      form.value.validate((valid) => {
        if (valid) {
          proxy
            .$post(url, params)
            .then((result) => {
              if (result.resultState == 200) {
                const { msg } = result;
                ElMessage({
                  message: `${msg}`,
                  type: "success",
                });
                router.push({
                  path: "/content",
                });
              }
            })
            .catch((err) => { });
        }
      });
    };

    const cancel = () => {
      router.push({
        path: "/content/identifyAreas/interpretationStrongOperators",
      });
    };
    const setArr = (arr) => {
      //Set数据结构，它类似于数组，其成员的值都是唯一的
      return Array.from(new Set(arr)); // 利用Array.from将Set结构转换成数组
    };

    return {
      ...toRefs(state),
      setArr,
      addIndustry,
      delIndustry,
      addSubject,
      delSubject,
      addStock,
      delStock,
      addEffect,
      delEffect,
      changeIndustryDegree,
      changeSubjectDegree,
      changeStockInfo,
      validateStockCode,
      submit,
      cancel,
      form,
    };
  },
};
</script>

<style lang="scss" scoped>
.add-container {
  .title {
    font-weight: bold;
  }
  .policy-content-form {
    padding: 20px;
    width: 450px;
  }
  .policy-analysis-form {
    padding: 20px;
    width: 490px;
    .choose-container {
      width: 370px;
      max-height: 260px;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 0 0 10px 0;
      .input {
        display: flex;
        .el-button {
          margin-left: 5px;
        }
      }
    }
  }
  .btn-group {
    display: flex;
    .btn {
      width: 80px;
      border: 1px solid #dcdcdc;
      height: 30px;
      line-height: 30px;
      text-align: center;
      border-radius: 5px;
      cursor: pointer;
      &.submit {
        background: #38a28a;
        color: #fff;
        margin-right: 15px;
      }
    }
  }
}
</style>
