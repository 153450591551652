export function passwordEncrypte(that, pwd) {
    let str = "",
    arr = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "a",
      "b",
      "c",
      "d",
      "e",
      "f"
    ]; // 随机产生
  for (var i = 0; i < 8; i++) {
    const pos = Math.round(Math.random() * (arr.length - 1));
    str += arr[pos];
  }
  let res = str + pwd;
  const CryptoJS = that.$CryptoJS;
  const key = CryptoJS.enc.Utf8.parse("QME_SH_YUANLU_JJ");
  const password = CryptoJS.enc.Utf8.parse(res);
  const encrypted = CryptoJS.AES.encrypt(password, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  const encryptedPwd = encrypted.toString();
  return encryptedPwd;
}

export function formatterTime(timeList) {
    let time = timeList;
    let month = '', date = '', hour = '', minute = '', second = '';
    month = `${time[1] < 10 ? `0${time[1]}` : `${time[1]}`}`;
    date = `${time[2] < 10 ? `0${time[2]}` : `${time[2]}`}`;
    hour = `${time[3] < 10 ? `0${time[3]}` : `${time[3]}`}`;
    minute = `${time[4] < 10 ? `0${time[4]}` : `${time[4]}`}`;
    second = `${time[5] < 10 ? `0${time[5]}` : `${time[5]}`}`;

    return `${time[0]}-${month}-${date} ${hour}:${minute}:${second}`
}

export function formatterDate(timeList) {
  let time = timeList;

  return `${time[0]}-${time[1] < 10 ? `0${time[1]}` : `${time[1]}`}-${time[2] < 10 ? `0${time[2]}` : `${time[2]}`}`
}